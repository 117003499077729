[data-hui=Swapper] > button {
  /* LAYOUT-POSITION */
  --hui-tysnrg-position: relative;
  /* LAYOUT-SIZING */
  --hui-tysnrg-height: 100%;
  --hui-tysnrg-width: 100%;
  /* LAYOUT-MARGIN */
  --hui-tysnrg-margin-top: 0;
  --hui-tysnrg-margin-right: 0;
  --hui-tysnrg-margin-bottom: 0;
  --hui-tysnrg-margin-left: 0;
  /* LAYOUT-PADDING */
  --hui-tysnrg-padding-top: 0;
  --hui-tysnrg-padding-right: 0;
  --hui-tysnrg-padding-bottom: 0;
  --hui-tysnrg-padding-left: 0;
  /* TEXT */
  --hui-tysnrg-text-align: center;
  /* DISPLAY */
  --hui-tysnrg-display: block;
}
[data-hui=Swapper] > button > [data-hui=SwapperDropZone] {
  /* LAYOUT-POSITION */
  --hui-tyysau-position: absolute;
  /* LAYOUT-SIZING */
  --hui-tyysau-height: 100%;
  --hui-tyysau-width: 25%;
  /* TEXT */
  --hui-tyysau-line-height: 100%;
  --hui-tyysau-text-align: center;
  /* APPERANCE */
  --hui-tyysau-background-color: #0000004f;
}
[data-hui=Swapper] > button > [data-hui=SwapperDropZone]:first-child {
  --hui-holwtq-left: 0;
  --hui-holwtq-right: auto;
  --hui-holwtq-top: 0;
  --hui-holwtq-bottom: 0;
}
[data-hui=Swapper] > button > [data-hui=SwapperDropZone]:last-child {
  --hui-fgbxgo-left: auto;
  --hui-fgbxgo-right: 0;
  --hui-fgbxgo-top: 0;
  --hui-fgbxgo-bottom: 0;
}
[data-hui=Swapper][data-hui-theme~=vertical] > button {
}
[data-hui=Swapper][data-hui-theme~=vertical] > button > [data-hui=SwapperDropZone] {
  /* LAYOUT-POSITION */
  --hui-tyysau-top: auto;
  --hui-tyysau-bottom: auto;
  /* LAYOUT-SIZING */
  --hui-tyysau-height: 50%;
  --hui-tyysau-width: 100%;
}
[data-hui=Swapper][data-hui-theme~=vertical] > button > [data-hui=SwapperDropZone]:first-child {
  --hui-holwtq-left: 0;
  --hui-holwtq-right: 0;
  --hui-holwtq-top: 0;
  --hui-holwtq-bottom: auto;
}
[data-hui=Swapper][data-hui-theme~=vertical] > button > [data-hui=SwapperDropZone]:last-child {
  --hui-fgbxgo-left: 0;
  --hui-fgbxgo-right: 0;
  --hui-fgbxgo-top: auto;
  --hui-fgbxgo-bottom: 0;
}
[data-hui=Swapper] > button {
  /* LAYOUT-POSITION */
/*  --hui-tysnrg-position-default: ; */
/*  --hui-tysnrg-top-default: ; */
/*  --hui-tysnrg-right-default: ; */
/*  --hui-tysnrg-bottom-default: ; */
/*  --hui-tysnrg-left-default: ; */
/*  --hui-tysnrg-z-index-default: ; */
/*  --hui-tysnrg-float-default: ; */
/*  --hui-tysnrg-clear-default: ; */
/*  --hui-tysnrg-align-self-default: ; */
/*  --hui-tysnrg-order-default: ; */
  /* LAYOUT-SIZING */
/*  --hui-tysnrg-box-sizing-default: ; */
/*  --hui-tysnrg-height-default: ; */
/*  --hui-tysnrg-max-height-default: ; */
/*  --hui-tysnrg-min-height-default: ; */
/*  --hui-tysnrg-width-default: ; */
/*  --hui-tysnrg-max-width-default: ; */
/*  --hui-tysnrg-min-width-default: ; */
  /* LAYOUT-MARGIN */
/*  --hui-tysnrg-margin-top-default: ; */
/*  --hui-tysnrg-margin-right-default: ; */
/*  --hui-tysnrg-margin-bottom-default: ; */
/*  --hui-tysnrg-margin-left-default: ; */
  /* LAYOUT-PADDING */
/*  --hui-tysnrg-padding-top-default: ; */
/*  --hui-tysnrg-padding-right-default: ; */
/*  --hui-tysnrg-padding-bottom-default: ; */
/*  --hui-tysnrg-padding-left-default: ; */
  /* LAYOUT-OVERFLOW */
/*  --hui-tysnrg-overflow-x-default: ; */
/*  --hui-tysnrg-overflow-y-default: ; */
  /* LAYOUT-FLEX */
/*  --hui-tysnrg-flex-basis-default: ; */
/*  --hui-tysnrg-flex-direction-default: ; */
/*  --hui-tysnrg-flex-grow-default: ; */
/*  --hui-tysnrg-flex-shrink-default: ; */
/*  --hui-tysnrg-flex-wrap-default: ; */
/*  --hui-tysnrg-gap-default: ; */
/*  --hui-tysnrg-align-content-default: ; */
/*  --hui-tysnrg-justify-content-default: ; */
/*  --hui-tysnrg-align-items-default: ; */
  /* TEXT */
/*  --hui-tysnrg-direction-default: ; */
/*  --hui-tysnrg-font-family-default: ; */
/*  --hui-tysnrg-font-size-default: ; */
/*  --hui-tysnrg-font-stretch-default: ; */
/*  --hui-tysnrg-font-style-default: ; */
/*  --hui-tysnrg-font-weight-default: ; */
/*  --hui-tysnrg-letter-spacing-default: ; */
/*  --hui-tysnrg-line-height-default: ; */
/*  --hui-tysnrg-text-align-default: ; */
/*  --hui-tysnrg-text-align-last-default: ; */
/*  --hui-tysnrg-text-decoration-color-default: ; */
/*  --hui-tysnrg-text-decoration-line-default: ; */
/*  --hui-tysnrg-text-decoration-style-default: ; */
/*  --hui-tysnrg-text-decoration-thickness-default: ; */
/*  --hui-tysnrg-text-indent-default: ; */
/*  --hui-tysnrg-text-overflow-default: ; */
/*  --hui-tysnrg-text-shadow-default: ; */
/*  --hui-tysnrg-text-size-adjust-default: ; */
/*  --hui-tysnrg-text-transform-default: ; */
/*  --hui-tysnrg-text-wrap-default: ; */
/*  --hui-tysnrg-vertical-align-default: ; */
/*  --hui-tysnrg-white-space-collapse-default: ; */
/*  --hui-tysnrg-word-break-default: ; */
/*  --hui-tysnrg-word-spacing-default: ; */
  /* APPERANCE */
/*  --hui-tysnrg-visibility-default: ; */
/*  --hui-tysnrg-color-default: ; */
/*  --hui-tysnrg-background-attachment-default: ; */
/*  --hui-tysnrg-background-clip-default: ; */
/*  --hui-tysnrg-background-color-default: ; */
/*  --hui-tysnrg-background-image-default: ; */
/*  --hui-tysnrg-background-origin-default: ; */
/*  --hui-tysnrg-background-position-x-default: ; */
/*  --hui-tysnrg-background-position-y-default: ; */
/*  --hui-tysnrg-background-repeat-default: ; */
/*  --hui-tysnrg-background-size-default: ; */
/*  --hui-tysnrg-border-bottom-color-default: ; */
/*  --hui-tysnrg-border-bottom-style-default: ; */
/*  --hui-tysnrg-border-bottom-width-default: ; */
/*  --hui-tysnrg-border-bottom-left-radius-default: ; */
/*  --hui-tysnrg-border-bottom-right-radius-default: ; */
/*  --hui-tysnrg-border-image-outset-default: ; */
/*  --hui-tysnrg-border-image-repeat-default: ; */
/*  --hui-tysnrg-border-image-slice-default: ; */
/*  --hui-tysnrg-border-image-source-default: ; */
/*  --hui-tysnrg-border-image-width-default: ; */
/*  --hui-tysnrg-border-left-color-default: ; */
/*  --hui-tysnrg-border-left-style-default: ; */
/*  --hui-tysnrg-border-left-width-default: ; */
/*  --hui-tysnrg-border-right-color-default: ; */
/*  --hui-tysnrg-border-right-style-default: ; */
/*  --hui-tysnrg-border-right-width-default: ; */
/*  --hui-tysnrg-border-top-color-default: ; */
/*  --hui-tysnrg-border-top-style-default: ; */
/*  --hui-tysnrg-border-top-width-default: ; */
/*  --hui-tysnrg-border-top-left-radius-default: ; */
/*  --hui-tysnrg-border-top-right-radius-default: ; */
/*  --hui-tysnrg-box-shadow-default: ; */
/*  --hui-tysnrg-cursor-default: ; */
/*  --hui-tysnrg-outline-color-default: ; */
/*  --hui-tysnrg-outline-offset-default: ; */
/*  --hui-tysnrg-outline-style-default: ; */
/*  --hui-tysnrg-outline-width-default: ; */
/*  --hui-tysnrg-scrollbar-color-default: ; */
/*  --hui-tysnrg-scrollbar-gutter-default: ; */
/*  --hui-tysnrg-scrollbar-width-default: ; */
  /* DISPLAY */
/*  --hui-tysnrg-display-default: ; */
}
[data-hui=Swapper] > button > [data-hui=SwapperDropZone] {
  /* LAYOUT-POSITION */
/*  --hui-tyysau-position-default: ; */
/*  --hui-tyysau-top-default: ; */
/*  --hui-tyysau-right-default: ; */
/*  --hui-tyysau-bottom-default: ; */
/*  --hui-tyysau-left-default: ; */
/*  --hui-tyysau-z-index-default: ; */
/*  --hui-tyysau-float-default: ; */
/*  --hui-tyysau-clear-default: ; */
/*  --hui-tyysau-align-self-default: ; */
/*  --hui-tyysau-order-default: ; */
  /* LAYOUT-SIZING */
/*  --hui-tyysau-box-sizing-default: ; */
/*  --hui-tyysau-height-default: ; */
/*  --hui-tyysau-max-height-default: ; */
/*  --hui-tyysau-min-height-default: ; */
/*  --hui-tyysau-width-default: ; */
/*  --hui-tyysau-max-width-default: ; */
/*  --hui-tyysau-min-width-default: ; */
  /* LAYOUT-MARGIN */
/*  --hui-tyysau-margin-top-default: ; */
/*  --hui-tyysau-margin-right-default: ; */
/*  --hui-tyysau-margin-bottom-default: ; */
/*  --hui-tyysau-margin-left-default: ; */
  /* LAYOUT-PADDING */
/*  --hui-tyysau-padding-top-default: ; */
/*  --hui-tyysau-padding-right-default: ; */
/*  --hui-tyysau-padding-bottom-default: ; */
/*  --hui-tyysau-padding-left-default: ; */
  /* LAYOUT-OVERFLOW */
/*  --hui-tyysau-overflow-x-default: ; */
/*  --hui-tyysau-overflow-y-default: ; */
  /* LAYOUT-FLEX */
/*  --hui-tyysau-flex-basis-default: ; */
/*  --hui-tyysau-flex-direction-default: ; */
/*  --hui-tyysau-flex-grow-default: ; */
/*  --hui-tyysau-flex-shrink-default: ; */
/*  --hui-tyysau-flex-wrap-default: ; */
/*  --hui-tyysau-gap-default: ; */
/*  --hui-tyysau-align-content-default: ; */
/*  --hui-tyysau-justify-content-default: ; */
/*  --hui-tyysau-align-items-default: ; */
  /* TEXT */
/*  --hui-tyysau-direction-default: ; */
/*  --hui-tyysau-font-family-default: ; */
/*  --hui-tyysau-font-size-default: ; */
/*  --hui-tyysau-font-stretch-default: ; */
/*  --hui-tyysau-font-style-default: ; */
/*  --hui-tyysau-font-weight-default: ; */
/*  --hui-tyysau-letter-spacing-default: ; */
/*  --hui-tyysau-line-height-default: ; */
/*  --hui-tyysau-text-align-default: ; */
/*  --hui-tyysau-text-align-last-default: ; */
/*  --hui-tyysau-text-decoration-color-default: ; */
/*  --hui-tyysau-text-decoration-line-default: ; */
/*  --hui-tyysau-text-decoration-style-default: ; */
/*  --hui-tyysau-text-decoration-thickness-default: ; */
/*  --hui-tyysau-text-indent-default: ; */
/*  --hui-tyysau-text-overflow-default: ; */
/*  --hui-tyysau-text-shadow-default: ; */
/*  --hui-tyysau-text-size-adjust-default: ; */
/*  --hui-tyysau-text-transform-default: ; */
/*  --hui-tyysau-text-wrap-default: ; */
/*  --hui-tyysau-vertical-align-default: ; */
/*  --hui-tyysau-white-space-collapse-default: ; */
/*  --hui-tyysau-word-break-default: ; */
/*  --hui-tyysau-word-spacing-default: ; */
  /* APPERANCE */
/*  --hui-tyysau-visibility-default: ; */
/*  --hui-tyysau-color-default: ; */
/*  --hui-tyysau-background-attachment-default: ; */
/*  --hui-tyysau-background-clip-default: ; */
/*  --hui-tyysau-background-color-default: ; */
/*  --hui-tyysau-background-image-default: ; */
/*  --hui-tyysau-background-origin-default: ; */
/*  --hui-tyysau-background-position-x-default: ; */
/*  --hui-tyysau-background-position-y-default: ; */
/*  --hui-tyysau-background-repeat-default: ; */
/*  --hui-tyysau-background-size-default: ; */
/*  --hui-tyysau-border-bottom-color-default: ; */
/*  --hui-tyysau-border-bottom-style-default: ; */
/*  --hui-tyysau-border-bottom-width-default: ; */
/*  --hui-tyysau-border-bottom-left-radius-default: ; */
/*  --hui-tyysau-border-bottom-right-radius-default: ; */
/*  --hui-tyysau-border-image-outset-default: ; */
/*  --hui-tyysau-border-image-repeat-default: ; */
/*  --hui-tyysau-border-image-slice-default: ; */
/*  --hui-tyysau-border-image-source-default: ; */
/*  --hui-tyysau-border-image-width-default: ; */
/*  --hui-tyysau-border-left-color-default: ; */
/*  --hui-tyysau-border-left-style-default: ; */
/*  --hui-tyysau-border-left-width-default: ; */
/*  --hui-tyysau-border-right-color-default: ; */
/*  --hui-tyysau-border-right-style-default: ; */
/*  --hui-tyysau-border-right-width-default: ; */
/*  --hui-tyysau-border-top-color-default: ; */
/*  --hui-tyysau-border-top-style-default: ; */
/*  --hui-tyysau-border-top-width-default: ; */
/*  --hui-tyysau-border-top-left-radius-default: ; */
/*  --hui-tyysau-border-top-right-radius-default: ; */
/*  --hui-tyysau-box-shadow-default: ; */
/*  --hui-tyysau-cursor-default: ; */
/*  --hui-tyysau-outline-color-default: ; */
/*  --hui-tyysau-outline-offset-default: ; */
/*  --hui-tyysau-outline-style-default: ; */
/*  --hui-tyysau-outline-width-default: ; */
/*  --hui-tyysau-scrollbar-color-default: ; */
/*  --hui-tyysau-scrollbar-gutter-default: ; */
/*  --hui-tyysau-scrollbar-width-default: ; */
}
[data-hui=Swapper] > button > [data-hui=SwapperDropZone]:first-child {
/*  --hui-holwtq-left-default: ; */
/*  --hui-holwtq-right-default: ; */
/*  --hui-holwtq-top-default: ; */
/*  --hui-holwtq-bottom-default: ; */
}
[data-hui=Swapper] > button > [data-hui=SwapperDropZone]:last-child {
/*  --hui-fgbxgo-left-default: ; */
/*  --hui-fgbxgo-right-default: ; */
/*  --hui-fgbxgo-top-default: ; */
/*  --hui-fgbxgo-bottom-default: ; */
}