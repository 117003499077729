data[data-hui] {
  display: contents;
}

[data-hui][hidden]:not(#huiHack) {
  display: none;
}

/* FLEX */
[data-hui-flex][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex][data-hui-target=self]:not(#huiHack) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
[data-hui-flex=inline-flex][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex=inline-flex][data-hui-target=self]:not(#huiHack) {
  display: inline-flex;
}
[data-hui-flex-direction=row][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-direction=row][data-hui-target=self]:not(#huiHack) {
  flex-direction: row;
}
[data-hui-flex-direction=row-reverse][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-direction=row-reverse][data-hui-target=self]:not(#huiHack) {
  flex-direction: row-reverse;
}
[data-hui-flex-direction=column][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-direction=column][data-hui-target=self]:not(#huiHack) {
  flex-direction: column;
}
[data-hui-flex-direction=column-reverse][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-direction=column-reverse][data-hui-target=self]:not(#huiHack) {
  flex-direction: column-reverse;
}
[data-hui-flex-wrap=nowrap][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-wrap=nowrap][data-hui-target=self]:not(#huiHack) {
  flex-wrap: nowrap;
}
[data-hui-flex-wrap=wrap][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-wrap=wrap][data-hui-target=self]:not(#huiHack) {
  flex-wrap: wrap;
}
[data-hui-flex-wrap=wrap-reverse][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-wrap=wrap-reverse][data-hui-target=self]:not(#huiHack) {
  flex-wrap: wrap-reverse;
}
[data-hui-flex-justify-content=flex-start][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=flex-start][data-hui-target=self]:not(#huiHack),
[data-hui-flex-justify-content=start][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=start][data-hui-target=self]:not(#huiHack),
[data-hui-flex-justify-content=left][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=left][data-hui-target=self]:not(#huiHack) {
  justify-content: flex-start;
}
[data-hui-flex-justify-content=flex-end][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=flex-end][data-hui-target=self]:not(#huiHack),
[data-hui-flex-justify-content=end][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=end][data-hui-target=self]:not(#huiHack),
[data-hui-flex-justify-content=right][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=right][data-hui-target=self]:not(#huiHack) {
  justify-content: flex-end;
}
[data-hui-flex-justify-content=center][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=center][data-hui-target=self]:not(#huiHack) {
  justify-content: center;
}
[data-hui-flex-justify-content=space-between][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=space-between][data-hui-target=self]:not(#huiHack) {
  justify-content: space-between;
}
[data-hui-flex-justify-content=space-around][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=space-around][data-hui-target=self]:not(#huiHack) {
  justify-content: space-around;
}
[data-hui-flex-justify-content=space-evenly][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-justify-content=space-evenly][data-hui-target=self]:not(#huiHack) {
  justify-content: space-evenly;
}
[data-hui-flex-align-items=flex-start][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=flex-start][data-hui-target=self]:not(#huiHack),
[data-hui-flex-align-items=start][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=start][data-hui-target=self]:not(#huiHack),
[data-hui-flex-align-items=left][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=left][data-hui-target=self]:not(#huiHack) {
  align-items: flex-start;
}
[data-hui-flex-align-items=flex-end][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=flex-end][data-hui-target=self]:not(#huiHack),
[data-hui-flex-align-items=end][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=end][data-hui-target=self]:not(#huiHack),
[data-hui-flex-align-items=right][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=right][data-hui-target=self]:not(#huiHack) {
  align-items: flex-end;
}
[data-hui-flex-align-items=center][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=center][data-hui-target=self]:not(#huiHack) {
  align-items: center;
}
[data-hui-flex-align-items=baseline][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=baseline][data-hui-target=self]:not(#huiHack) {
  align-items: baseline;
}
[data-hui-flex-align-items=stretch][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-items=stretch][data-hui-target=self]:not(#huiHack) {
  align-items: stretch;
}
[data-hui-flex-align-content=flex-start][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-content=flex-start][data-hui-target=self]:not(#huiHack) {
  align-content: flex-start;
}
[data-hui-flex-align-content=flex-end][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-content=flex-end][data-hui-target=self]:not(#huiHack) {
  align-content: flex-end;
}
[data-hui-flex-align-content=center][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-content=center][data-hui-target=self]:not(#huiHack) {
  align-content: center;
}
[data-hui-flex-align-content=space-between][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-content=space-between][data-hui-target=self]:not(#huiHack) {
  align-content: space-between;
}
[data-hui-flex-align-content=space-around][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-content=space-around][data-hui-target=self]:not(#huiHack) {
  align-content: space-around;
}
[data-hui-flex-align-content=space-evenly][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-content=space-evenly][data-hui-target=self]:not(#huiHack) {
  align-content: space-evenly;
}
[data-hui-flex-align-content=stretch][data-hui-target=child] > *:not(#huiHack),
[data-hui-flex-align-content=stretch][data-hui-target=self]:not(#huiHack) {
  align-content: stretch;
}
[data-hui-flex][data-hui-target=child][style~='row-gap:'] > *:not(#huiHack) {
  row-gap: inherit;
}
[data-hui-flex][data-hui-target=child][style~='column-gap:'] > *:not(#huiHack) {
  column-gap: inherit;
}
[data-hui-flex][data-hui-target=child][style~='gap:'] > *:not(#huiHack) {
  gap: inherit;
}

/* GRID */
[data-hui-grid][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid][data-hui-target=self]:not(#huiHack) {
  display: grid;
  justify-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
  grid-auto-flow: row;
}
[data-hui-grid=inline-grid][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid=inline-grid][data-hui-target=self]:not(#huiHack) {
  display: inline-grid;
}
[data-hui-grid=subgrid][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid=subgrid][data-hui-target=self]:not(#huiHack) {
  display: subgrid;
}
[data-hui-grid-justify-items=start][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-items=start][data-hui-target=self]:not(#huiHack) {
  justify-items: start;
}
[data-hui-grid-justify-items=end][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-items=end][data-hui-target=self]:not(#huiHack) {
  justify-items: end;
}
[data-hui-grid-justify-items=center][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-items=center][data-hui-target=self]:not(#huiHack) {
  justify-items: center;
}
[data-hui-grid-justify-items=stretch][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-items=stretch][data-hui-target=self]:not(#huiHack) {
  justify-items: stretch;
}
[data-hui-grid-align-items=start][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-items=start][data-hui-target=self]:not(#huiHack) {
  align-items: start;
}
[data-hui-grid-align-items=end][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-items=end][data-hui-target=self]:not(#huiHack) {
  align-items: end;
}
[data-hui-grid-align-items=center][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-items=center][data-hui-target=self]:not(#huiHack) {
  align-items: center;
}
[data-hui-grid-align-items=stretch][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-items=stretch][data-hui-target=self]:not(#huiHack) {
  align-items: stretch;
}
[data-hui-grid-justify-content=start][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-content=start][data-hui-target=self]:not(#huiHack) {
  justify-content: start;
}
[data-hui-grid-justify-content=end][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-content=end][data-hui-target=self]:not(#huiHack) {
  justify-content: end;
}
[data-hui-grid-justify-content=center][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-content=center][data-hui-target=self]:not(#huiHack) {
  justify-content: center;
}
[data-hui-grid-justify-content=stretch][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-content=stretch][data-hui-target=self]:not(#huiHack) {
  justify-content: stretch;
}
[data-hui-grid-justify-content=space-around][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-content=space-around][data-hui-target=self]:not(#huiHack) {
  justify-content: space-around;
}
[data-hui-grid-justify-content=space-between][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-content=space-between][data-hui-target=self]:not(#huiHack) {
  justify-content: space-between;
}
[data-hui-grid-justify-content=space-evenly][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-justify-content=space-evenly][data-hui-target=self]:not(#huiHack) {
  justify-content: space-evenly;
}
[data-hui-grid-align-content=start][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-content=start][data-hui-target=self]:not(#huiHack) {
  align-content: start;
}
[data-hui-grid-align-content=end][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-content=end][data-hui-target=self]:not(#huiHack) {
  align-content: end;
}
[data-hui-grid-align-content=center][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-content=center][data-hui-target=self]:not(#huiHack) {
  align-content: center;
}
[data-hui-grid-align-content=stretch][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-content=stretch][data-hui-target=self]:not(#huiHack) {
  align-content: stretch;
}
[data-hui-grid-align-content=space-around][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-content=space-around][data-hui-target=self]:not(#huiHack) {
  align-content: space-around;
}
[data-hui-grid-align-content=space-between][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-content=space-between][data-hui-target=self]:not(#huiHack) {
  align-content: space-between;
}
[data-hui-grid-align-content=space-evenly][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-align-content=space-evenly][data-hui-target=self]:not(#huiHack) {
  align-content: space-evenly;
}
[data-hui-grid-auto-flow='row dense'][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-auto-flow='row dense'][data-hui-target=self]:not(#huiHack) {
  grid-auto-flow: row dense;
}
[data-hui-grid-auto-flow='column dense'][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-auto-flow='column dense'][data-hui-target=self]:not(#huiHack) {
  grid-auto-flow: column dense;
}
[data-hui-grid-auto-flow=row][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-auto-flow=row][data-hui-target=self]:not(#huiHack) {
  grid-auto-flow: row;
}
[data-hui-grid-auto-flow=column][data-hui-target=child] > *:not(#huiHack),
[data-hui-grid-auto-flow=column][data-hui-target=self]:not(#huiHack) {
  grid-auto-flow: column;
}
[data-hui-grid][data-hui-target=child][style~='grid-template-columns:'] > *:not(#huiHack) {
  grid-template-columns: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-template-rows:'] > *:not(#huiHack) {
  grid-template-rows: inherit;
}
[data-hui-grid][data-hui-target=child][style~='row-gap:'] > *:not(#huiHack) {
  row-gap: inherit;
}
[data-hui-grid][data-hui-target=child][style~='column-gap:'] > *:not(#huiHack) {
  column-gap: inherit;
}
[data-hui-grid][data-hui-target=child][style~='gap:'] > *:not(#huiHack) {
  gap: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-column:'] > *:not(#huiHack) {
  grid-column: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-column-start:'] > *:not(#huiHack) {
  grid-column-start: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-column-end:'] > *:not(#huiHack) {
  grid-column-end: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-row:'] > *:not(#huiHack) {
  grid-row: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-row-start:'] > *:not(#huiHack) {
  grid-row-start: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-row-end:'] > *:not(#huiHack) {
  grid-row-end: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-auto-rows:'] > *:not(#huiHack) {
  grid-auto-rows: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-auto-columns:'] > *:not(#huiHack) {
  grid-auto-columns: inherit;
}
[data-hui-grid][data-hui-target=child][style~='grid-template-areas:'] > *:not(#huiHack) {
  grid-template-areas: inherit;
}

/* SCROLL */
[data-hui-scroll-x=true][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-x=true][data-hui-target=self]:not(#huiHack),
[data-hui-scroll-x=auto][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-x=auto][data-hui-target=self]:not(#huiHack) {
  overflow-x: auto;
}
[data-hui-scroll-y=true][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-y=true][data-hui-target=self]:not(#huiHack),
[data-hui-scroll-y=auto][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-y=auto][data-hui-target=self]:not(#huiHack) {
  overflow-y: auto;
}
[data-hui-scroll-x=hidden][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-x=hidden][data-hui-target=self]:not(#huiHack) {
  overflow-x: hidden;
}
[data-hui-scroll-y=hidden][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-y=hidden][data-hui-target=self]:not(#huiHack) {
  overflow-y: hidden;
}
[data-hui-scroll-x=scroll][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-x=scroll][data-hui-target=self]:not(#huiHack) {
  overflow-x: scroll;
}
[data-hui-scroll-y=scroll][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-y=scroll][data-hui-target=self]:not(#huiHack) {
  overflow-y: scroll;
}
[data-hui-scroll-x=visible][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-x=visible][data-hui-target=self]:not(#huiHack) {
  overflow-x: visible;
}
[data-hui-scroll-y=visible][data-hui-target=child] > *:not(#huiHack),
[data-hui-scroll-y=visible][data-hui-target=self]:not(#huiHack) {
  overflow-y: visible;
}

[data-hui-scroll-y],
[data-hui-scroll-x] {
  scrollbar-width: thin;
  scrollbar-color: #bbb #eee;
  scrollbar-gutter: auto;
}

/* POSITION */
[data-hui-position=absolute][data-hui-target=child] > *:not(#huiHack),
[data-hui-position=absolute][data-hui-target=self]:not(#huiHack) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[data-hui-position=relative][data-hui-target=child] > *:not(#huiHack),
[data-hui-position=relative][data-hui-target=self]:not(#huiHack) {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[data-hui-position=fixed][data-hui-target=child] > *:not(#huiHack),
[data-hui-position=fixed][data-hui-target=self]:not(#huiHack) {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[data-hui-position=sticky][data-hui-target=child] > *:not(#huiHack),
[data-hui-position=sticky][data-hui-target=self]:not(#huiHack) {
  position: sticky;
}
[data-hui-position=static][data-hui-target=child] > *:not(#huiHack),
[data-hui-position=static][data-hui-target=self]:not(#huiHack) {
  position: static;
}
[data-hui-position][data-hui-target=child][style~='top:'] > *:not(#huiHack) {
  top: inherit;
}
[data-hui-position][data-hui-target=child][style~='right:'] > *:not(#huiHack) {
  right: inherit;
}
[data-hui-position][data-hui-target=child][style~='bottom:'] > *:not(#huiHack) {
  bottom: inherit;
}
[data-hui-position][data-hui-target=child][style~='left:'] > *:not(#huiHack) {
  left: inherit;
}
[data-hui-position][data-hui-target=child][style~='inset:'] > *:not(#huiHack) {
  inset: inherit;
}

/* MARGIN */
[data-hui][data-hui-target=child][style~='margin:'] > *:not(#huiHack) {
  margin: inherit;
}
[data-hui][data-hui-target=child][style~='margin-top:'] > *:not(#huiHack) {
  margin-top: inherit;
}
[data-hui][data-hui-target=child][style~='margin-right:'] > *:not(#huiHack) {
  margin-right: inherit;
}
[data-hui][data-hui-target=child][style~='margin-bottom:'] > *:not(#huiHack) {
  margin-bottom: inherit;
}
[data-hui][data-hui-target=child][style~='margin-left:'] > *:not(#huiHack) {
  margin-left: inherit;
}

/* PADDING */
[data-hui][data-hui-target=child][style~='padding:'] > *:not(#huiHack) {
  padding: inherit;
}
[data-hui][data-hui-target=child][style~='padding-top:'] > *:not(#huiHack) {
  padding-top: inherit;
}
[data-hui][data-hui-target=child][style~='padding-right:'] > *:not(#huiHack) {
  padding-right: inherit;
}
[data-hui][data-hui-target=child][style~='padding-bottom:'] > *:not(#huiHack) {
  padding-bottom: inherit;
}
[data-hui][data-hui-target=child][style~='padding-left:'] > *:not(#huiHack) {
  padding-left: inherit;
}

/* BACKGROUND */
[data-hui][data-hui-target=child][style~='background-color:'] > *:not(#huiHack) {
  background-color: inherit;
}
[data-hui][data-hui-target=child][style~='background-image:'] > *:not(#huiHack) {
  background-image: inherit;
}
[data-hui][data-hui-target=child][style~='background-position:'] > *:not(#huiHack) {
  background-position: inherit;
}

/* SIZE */
[data-hui][data-hui-target=child][style~='width:'] > *:not(#huiHack) {
  width: inherit;
}
[data-hui][data-hui-target=child][style~='max-width:'] > *:not(#huiHack) {
  max-width: inherit;
}
[data-hui][data-hui-target=child][style~='min-width:'] > *:not(#huiHack) {
  min-width: inherit;
}
[data-hui][data-hui-target=child][style~='height:'] > *:not(#huiHack) {
  height: inherit;
}
[data-hui][data-hui-target=child][style~='max-height:'] > *:not(#huiHack) {
  max-height: inherit;
}
[data-hui][data-hui-target=child][style~='min-height:'] > *:not(#huiHack) {
  min-height: inherit;
}

/* Z-INDEX */
[data-hui-position][data-hui-z='0'][data-hui-target=child] > *:not(#huiHack),
[data-hui-position][data-hui-z='0'][data-hui-target=self]:not(#huiHack) {
  z-index: 0;
}
[data-hui-position][data-hui-z='1'][data-hui-target=child] > *:not(#huiHack),
[data-hui-position][data-hui-z='1'][data-hui-target=self]:not(#huiHack) {
  z-index: 1;
}
[data-hui-position][data-hui-z='2'][data-hui-target=child] > *:not(#huiHack),
[data-hui-position][data-hui-z='2'][data-hui-target=self]:not(#huiHack) {
  z-index: 10;
}
[data-hui-position][data-hui-z='3'][data-hui-target=child] > *:not(#huiHack),
[data-hui-position][data-hui-z='3'][data-hui-target=self]:not(#huiHack) {
  z-index: 100;
}
[data-hui-position][data-hui-z='4'][data-hui-target=child] > *:not(#huiHack),
[data-hui-position][data-hui-z='4'][data-hui-target=self]:not(#huiHack) {
  z-index: 1000;
}
[data-hui-position][data-hui-z='5'][data-hui-target=child] > *:not(#huiHack),
[data-hui-position][data-hui-z='5'][data-hui-target=self]:not(#huiHack) {
  z-index: 10000;
}

