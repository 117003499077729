[data-hui=Button],
[data-hui=Button]:hover,
[data-hui=Button]:active,
[data-hui=Button]:focus {
  /* LAYOUT-POSITION */
  position: var(--hui-pomgca-position, static);
  top: var(--hui-pomgca-top, auto);
  right: var(--hui-pomgca-right, auto);
  bottom: var(--hui-pomgca-bottom, auto);
  left: var(--hui-pomgca-left, auto);
  z-index: var(--hui-pomgca-z-index, auto);
  float: var(--hui-pomgca-float, none);
  clear: var(--hui-pomgca-clear, none);
  align-self: var(--hui-pomgca-align-self, auto);
  order: var(--hui-pomgca-order, 0);
  /* LAYOUT-SIZING */
  box-sizing: var(--hui-pomgca-box-sizing, border-box);
  height: var(--hui-pomgca-height, auto);
  max-height: var(--hui-pomgca-max-height, none);
  min-height: var(--hui-pomgca-min-height, auto);
  width: var(--hui-pomgca-width, auto);
  max-width: var(--hui-pomgca-max-width, none);
  min-width: var(--hui-pomgca-min-width, auto);
  /* LAYOUT-MARGIN */
  margin-top: var(--hui-pomgca-margin-top, 0px);
  margin-right: var(--hui-pomgca-margin-right, 0px);
  margin-bottom: var(--hui-pomgca-margin-bottom, 0px);
  margin-left: var(--hui-pomgca-margin-left, 0px);
  /* LAYOUT-PADDING */
  padding-top: var(--hui-pomgca-padding-top, 0px);
  padding-right: var(--hui-pomgca-padding-right, 0px);
  padding-bottom: var(--hui-pomgca-padding-bottom, 0px);
  padding-left: var(--hui-pomgca-padding-left, 0px);
  /* LAYOUT-OVERFLOW */
  overflow-x: var(--hui-pomgca-overflow-x, visible);
  overflow-y: var(--hui-pomgca-overflow-y, visible);
  /* LAYOUT-FLEX */
  flex-basis: var(--hui-pomgca-flex-basis, auto);
  flex-direction: var(--hui-pomgca-flex-direction, row);
  flex-grow: var(--hui-pomgca-flex-grow, 0);
  flex-shrink: var(--hui-pomgca-flex-shrink, 1);
  flex-wrap: var(--hui-pomgca-flex-wrap, nowrap);
  gap: var(--hui-pomgca-gap, 0px);
  align-content: var(--hui-pomgca-align-content, stretch);
  justify-content: var(--hui-pomgca-justify-content, flex-start);
  align-items: var(--hui-pomgca-align-items, stretch);
  /* TEXT */
  direction: var(--hui-pomgca-direction, ltr);
  font-family: var(--hui-pomgca-font-family, system-ui);
  font-size: var(--hui-pomgca-font-size, 16px);
  font-stretch: var(--hui-pomgca-font-stretch, 100%);
  font-style: var(--hui-pomgca-font-style, normal);
  font-weight: var(--hui-pomgca-font-weight, 400);
  letter-spacing: var(--hui-pomgca-letter-spacing, normal);
  line-height: var(--hui-pomgca-line-height, normal);
  text-align: var(--hui-pomgca-text-align, start);
  text-align-last: var(--hui-pomgca-text-align-last, auto);
  text-decoration-color: var(--hui-pomgca-text-decoration-color, #000);
  text-decoration-line: var(--hui-pomgca-text-decoration-line, none);
  text-decoration-style: var(--hui-pomgca-text-decoration-style, solid);
  text-decoration-thickness: var(--hui-pomgca-text-decoration-thickness, auto);
  text-indent: var(--hui-pomgca-text-indent, 0px);
  text-overflow: var(--hui-pomgca-text-overflow, clip);
  text-shadow: var(--hui-pomgca-text-shadow, none);
  text-size-adjust: var(--hui-pomgca-text-size-adjust, auto);
  text-transform: var(--hui-pomgca-text-transform, none);
  text-wrap: var(--hui-pomgca-text-wrap, wrap);
  vertical-align: var(--hui-pomgca-vertical-align, baseline);
  white-space-collapse: var(--hui-pomgca-white-space-collapse, collapse);
  word-break: var(--hui-pomgca-word-break, normal);
  word-spacing: var(--hui-pomgca-word-spacing, 0px);
  /* APPERANCE */
  visibility: var(--hui-pomgca-visibility, visible);
  color: var(--hui-pomgca-color, rgb(0, 0, 0));
  background-attachment: var(--hui-pomgca-background-attachment, scroll);
  background-clip: var(--hui-pomgca-background-clip, border-box);
  background-color: var(--hui-pomgca-background-color, rgba(0, 0, 0, 0));
  background-image: var(--hui-pomgca-background-image, none);
  background-origin: var(--hui-pomgca-background-origin, padding-box);
  background-position-x: var(--hui-pomgca-background-position-x, 0%);
  background-position-y: var(--hui-pomgca-background-position-y, 0%);
  background-repeat: var(--hui-pomgca-background-repeat, repeat);
  background-size: var(--hui-pomgca-background-size, auto);
  border-bottom-color: var(--hui-pomgca-border-bottom-color, rgb(0, 0, 0));
  border-bottom-style: var(--hui-pomgca-border-bottom-style, none);
  border-bottom-width: var(--hui-pomgca-border-bottom-width, 0px);
  border-bottom-left-radius: var(--hui-pomgca-border-bottom-left-radius, 0px);
  border-bottom-right-radius: var(--hui-pomgca-border-bottom-right-radius, 0px);
  border-image-outset: var(--hui-pomgca-border-image-outset, 0);
  border-image-repeat: var(--hui-pomgca-border-image-repeat, stretch);
  border-image-slice: var(--hui-pomgca-border-image-slice, 100%);
  border-image-source: var(--hui-pomgca-border-image-source, none);
  border-image-width: var(--hui-pomgca-border-image-width, 1);
  border-left-color: var(--hui-pomgca-border-left-color, rgb(0, 0, 0));
  border-left-style: var(--hui-pomgca-border-left-style, none);
  border-left-width: var(--hui-pomgca-border-left-width, 0px);
  border-right-color: var(--hui-pomgca-border-right-color, rgb(0, 0, 0));
  border-right-style: var(--hui-pomgca-border-right-style, none);
  border-right-width: var(--hui-pomgca-border-right-width, 0px);
  border-top-color: var(--hui-pomgca-border-top-color, rgb(0, 0, 0));
  border-top-style: var(--hui-pomgca-border-top-style, none);
  border-top-width: var(--hui-pomgca-border-top-width, 0px);
  border-top-left-radius: var(--hui-pomgca-border-top-left-radius, 0px);
  border-top-right-radius: var(--hui-pomgca-border-top-right-radius, 0px);
  box-shadow: var(--hui-pomgca-box-shadow, none);
  cursor: var(--hui-pomgca-cursor, auto);
  outline-color: var(--hui-pomgca-outline-color, rgb(0, 0, 0));
  outline-offset: var(--hui-pomgca-outline-offset, 0px);
  outline-style: var(--hui-pomgca-outline-style, none);
  outline-width: var(--hui-pomgca-outline-width, 0px);
  scrollbar-color: var(--hui-pomgca-scrollbar-color, #bbb #eee);
  scrollbar-gutter: var(--hui-pomgca-scrollbar-gutter, auto);
  scrollbar-width: var(--hui-pomgca-scrollbar-width, thin);
}
[data-hui=Button] > svg {
  /* LAYOUT-POSITION */
  position: var(--hui-fqjtnv-position, static);
  top: var(--hui-fqjtnv-top, auto);
  right: var(--hui-fqjtnv-right, auto);
  bottom: var(--hui-fqjtnv-bottom, auto);
  left: var(--hui-fqjtnv-left, auto);
  z-index: var(--hui-fqjtnv-z-index, auto);
  float: var(--hui-fqjtnv-float, none);
  clear: var(--hui-fqjtnv-clear, none);
  align-self: var(--hui-fqjtnv-align-self, auto);
  order: var(--hui-fqjtnv-order, 0);
  /* LAYOUT-SIZING */
  box-sizing: var(--hui-fqjtnv-box-sizing, border-box);
  height: var(--hui-fqjtnv-height, auto);
  max-height: var(--hui-fqjtnv-max-height, none);
  min-height: var(--hui-fqjtnv-min-height, auto);
  width: var(--hui-fqjtnv-width, auto);
  max-width: var(--hui-fqjtnv-max-width, none);
  min-width: var(--hui-fqjtnv-min-width, auto);
  /* LAYOUT-MARGIN */
  margin-top: var(--hui-fqjtnv-margin-top, 0px);
  margin-right: var(--hui-fqjtnv-margin-right, 0px);
  margin-bottom: var(--hui-fqjtnv-margin-bottom, 0px);
  margin-left: var(--hui-fqjtnv-margin-left, 0px);
  /* LAYOUT-PADDING */
  padding-top: var(--hui-fqjtnv-padding-top, 0px);
  padding-right: var(--hui-fqjtnv-padding-right, 0px);
  padding-bottom: var(--hui-fqjtnv-padding-bottom, 0px);
  padding-left: var(--hui-fqjtnv-padding-left, 0px);
  /* LAYOUT-OVERFLOW */
  overflow-x: var(--hui-fqjtnv-overflow-x, visible);
  overflow-y: var(--hui-fqjtnv-overflow-y, visible);
  /* LAYOUT-FLEX */
  flex-basis: var(--hui-fqjtnv-flex-basis, auto);
  flex-direction: var(--hui-fqjtnv-flex-direction, row);
  flex-grow: var(--hui-fqjtnv-flex-grow, 0);
  flex-shrink: var(--hui-fqjtnv-flex-shrink, 1);
  flex-wrap: var(--hui-fqjtnv-flex-wrap, nowrap);
  gap: var(--hui-fqjtnv-gap, 0px);
  align-content: var(--hui-fqjtnv-align-content, stretch);
  justify-content: var(--hui-fqjtnv-justify-content, flex-start);
  align-items: var(--hui-fqjtnv-align-items, stretch);
  /* TEXT */
  direction: var(--hui-fqjtnv-direction, ltr);
  font-family: var(--hui-fqjtnv-font-family, system-ui);
  font-size: var(--hui-fqjtnv-font-size, 16px);
  font-stretch: var(--hui-fqjtnv-font-stretch, 100%);
  font-style: var(--hui-fqjtnv-font-style, normal);
  font-weight: var(--hui-fqjtnv-font-weight, 400);
  letter-spacing: var(--hui-fqjtnv-letter-spacing, normal);
  line-height: var(--hui-fqjtnv-line-height, normal);
  text-align: var(--hui-fqjtnv-text-align, start);
  text-align-last: var(--hui-fqjtnv-text-align-last, auto);
  text-decoration-color: var(--hui-fqjtnv-text-decoration-color, #000);
  text-decoration-line: var(--hui-fqjtnv-text-decoration-line, none);
  text-decoration-style: var(--hui-fqjtnv-text-decoration-style, solid);
  text-decoration-thickness: var(--hui-fqjtnv-text-decoration-thickness, auto);
  text-indent: var(--hui-fqjtnv-text-indent, 0px);
  text-overflow: var(--hui-fqjtnv-text-overflow, clip);
  text-shadow: var(--hui-fqjtnv-text-shadow, none);
  text-size-adjust: var(--hui-fqjtnv-text-size-adjust, auto);
  text-transform: var(--hui-fqjtnv-text-transform, none);
  text-wrap: var(--hui-fqjtnv-text-wrap, wrap);
  vertical-align: var(--hui-fqjtnv-vertical-align, baseline);
  white-space-collapse: var(--hui-fqjtnv-white-space-collapse, collapse);
  word-break: var(--hui-fqjtnv-word-break, normal);
  word-spacing: var(--hui-fqjtnv-word-spacing, 0px);
  /* APPERANCE */
  visibility: var(--hui-fqjtnv-visibility, visible);
  color: var(--hui-fqjtnv-color, rgb(0, 0, 0));
  background-attachment: var(--hui-fqjtnv-background-attachment, scroll);
  background-clip: var(--hui-fqjtnv-background-clip, border-box);
  background-color: var(--hui-fqjtnv-background-color, rgba(0, 0, 0, 0));
  background-image: var(--hui-fqjtnv-background-image, none);
  background-origin: var(--hui-fqjtnv-background-origin, padding-box);
  background-position-x: var(--hui-fqjtnv-background-position-x, 0%);
  background-position-y: var(--hui-fqjtnv-background-position-y, 0%);
  background-repeat: var(--hui-fqjtnv-background-repeat, repeat);
  background-size: var(--hui-fqjtnv-background-size, auto);
  border-bottom-color: var(--hui-fqjtnv-border-bottom-color, rgb(0, 0, 0));
  border-bottom-style: var(--hui-fqjtnv-border-bottom-style, none);
  border-bottom-width: var(--hui-fqjtnv-border-bottom-width, 0px);
  border-bottom-left-radius: var(--hui-fqjtnv-border-bottom-left-radius, 0px);
  border-bottom-right-radius: var(--hui-fqjtnv-border-bottom-right-radius, 0px);
  border-image-outset: var(--hui-fqjtnv-border-image-outset, 0);
  border-image-repeat: var(--hui-fqjtnv-border-image-repeat, stretch);
  border-image-slice: var(--hui-fqjtnv-border-image-slice, 100%);
  border-image-source: var(--hui-fqjtnv-border-image-source, none);
  border-image-width: var(--hui-fqjtnv-border-image-width, 1);
  border-left-color: var(--hui-fqjtnv-border-left-color, rgb(0, 0, 0));
  border-left-style: var(--hui-fqjtnv-border-left-style, none);
  border-left-width: var(--hui-fqjtnv-border-left-width, 0px);
  border-right-color: var(--hui-fqjtnv-border-right-color, rgb(0, 0, 0));
  border-right-style: var(--hui-fqjtnv-border-right-style, none);
  border-right-width: var(--hui-fqjtnv-border-right-width, 0px);
  border-top-color: var(--hui-fqjtnv-border-top-color, rgb(0, 0, 0));
  border-top-style: var(--hui-fqjtnv-border-top-style, none);
  border-top-width: var(--hui-fqjtnv-border-top-width, 0px);
  border-top-left-radius: var(--hui-fqjtnv-border-top-left-radius, 0px);
  border-top-right-radius: var(--hui-fqjtnv-border-top-right-radius, 0px);
  box-shadow: var(--hui-fqjtnv-box-shadow, none);
  cursor: var(--hui-fqjtnv-cursor, auto);
  outline-color: var(--hui-fqjtnv-outline-color, rgb(0, 0, 0));
  outline-offset: var(--hui-fqjtnv-outline-offset, 0px);
  outline-style: var(--hui-fqjtnv-outline-style, none);
  outline-width: var(--hui-fqjtnv-outline-width, 0px);
  scrollbar-color: var(--hui-fqjtnv-scrollbar-color, #bbb #eee);
  scrollbar-gutter: var(--hui-fqjtnv-scrollbar-gutter, auto);
  scrollbar-width: var(--hui-fqjtnv-scrollbar-width, thin);
  /* DISPLAY */
  display: var(--hui-fqjtnv-display, block);
  stroke: var(--hui-fqjtnv-stroke, initial);
  stroke-width: var(--hui-fqjtnv-stroke-width, initial);
}