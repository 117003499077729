[data-hui=Small] {
  /* LAYOUT-POSITION */
  position: var(--hui-wpcfwr-position, static);
  top: var(--hui-wpcfwr-top, auto);
  right: var(--hui-wpcfwr-right, auto);
  bottom: var(--hui-wpcfwr-bottom, auto);
  left: var(--hui-wpcfwr-left, auto);
  z-index: var(--hui-wpcfwr-z-index, auto);
  float: var(--hui-wpcfwr-float, none);
  clear: var(--hui-wpcfwr-clear, none);
  align-self: var(--hui-wpcfwr-align-self, auto);
  order: var(--hui-wpcfwr-order, 0);
  /* LAYOUT-SIZING */
  box-sizing: var(--hui-wpcfwr-box-sizing, border-box);
  height: var(--hui-wpcfwr-height, auto);
  max-height: var(--hui-wpcfwr-max-height, none);
  min-height: var(--hui-wpcfwr-min-height, auto);
  width: var(--hui-wpcfwr-width, auto);
  max-width: var(--hui-wpcfwr-max-width, none);
  min-width: var(--hui-wpcfwr-min-width, auto);
  /* LAYOUT-MARGIN */
  margin-top: var(--hui-wpcfwr-margin-top, 0px);
  margin-right: var(--hui-wpcfwr-margin-right, 0px);
  margin-bottom: var(--hui-wpcfwr-margin-bottom, 0px);
  margin-left: var(--hui-wpcfwr-margin-left, 0px);
  /* LAYOUT-PADDING */
  padding-top: var(--hui-wpcfwr-padding-top, 0px);
  padding-right: var(--hui-wpcfwr-padding-right, 0px);
  padding-bottom: var(--hui-wpcfwr-padding-bottom, 0px);
  padding-left: var(--hui-wpcfwr-padding-left, 0px);
  /* LAYOUT-OVERFLOW */
  overflow-x: var(--hui-wpcfwr-overflow-x, visible);
  overflow-y: var(--hui-wpcfwr-overflow-y, visible);
  /* LAYOUT-FLEX */
  flex-basis: var(--hui-wpcfwr-flex-basis, auto);
  flex-direction: var(--hui-wpcfwr-flex-direction, row);
  flex-grow: var(--hui-wpcfwr-flex-grow, 0);
  flex-shrink: var(--hui-wpcfwr-flex-shrink, 1);
  flex-wrap: var(--hui-wpcfwr-flex-wrap, nowrap);
  gap: var(--hui-wpcfwr-gap, 0px);
  align-content: var(--hui-wpcfwr-align-content, stretch);
  justify-content: var(--hui-wpcfwr-justify-content, flex-start);
  align-items: var(--hui-wpcfwr-align-items, stretch);
  /* TEXT */
  direction: var(--hui-wpcfwr-direction, ltr);
  font-family: var(--hui-wpcfwr-font-family, system-ui);
  font-size: var(--hui-wpcfwr-font-size, 16px);
  font-stretch: var(--hui-wpcfwr-font-stretch, 100%);
  font-style: var(--hui-wpcfwr-font-style, normal);
  font-weight: var(--hui-wpcfwr-font-weight, 400);
  letter-spacing: var(--hui-wpcfwr-letter-spacing, normal);
  line-height: var(--hui-wpcfwr-line-height, normal);
  text-align: var(--hui-wpcfwr-text-align, start);
  text-align-last: var(--hui-wpcfwr-text-align-last, auto);
  text-decoration-color: var(--hui-wpcfwr-text-decoration-color, #000);
  text-decoration-line: var(--hui-wpcfwr-text-decoration-line, none);
  text-decoration-style: var(--hui-wpcfwr-text-decoration-style, solid);
  text-decoration-thickness: var(--hui-wpcfwr-text-decoration-thickness, auto);
  text-indent: var(--hui-wpcfwr-text-indent, 0px);
  text-overflow: var(--hui-wpcfwr-text-overflow, clip);
  text-shadow: var(--hui-wpcfwr-text-shadow, none);
  text-size-adjust: var(--hui-wpcfwr-text-size-adjust, auto);
  text-transform: var(--hui-wpcfwr-text-transform, none);
  text-wrap: var(--hui-wpcfwr-text-wrap, wrap);
  vertical-align: var(--hui-wpcfwr-vertical-align, baseline);
  white-space-collapse: var(--hui-wpcfwr-white-space-collapse, collapse);
  word-break: var(--hui-wpcfwr-word-break, normal);
  word-spacing: var(--hui-wpcfwr-word-spacing, 0px);
  /* APPERANCE */
  visibility: var(--hui-wpcfwr-visibility, visible);
  color: var(--hui-wpcfwr-color, rgb(0, 0, 0));
  background-attachment: var(--hui-wpcfwr-background-attachment, scroll);
  background-clip: var(--hui-wpcfwr-background-clip, border-box);
  background-color: var(--hui-wpcfwr-background-color, rgba(0, 0, 0, 0));
  background-image: var(--hui-wpcfwr-background-image, none);
  background-origin: var(--hui-wpcfwr-background-origin, padding-box);
  background-position-x: var(--hui-wpcfwr-background-position-x, 0%);
  background-position-y: var(--hui-wpcfwr-background-position-y, 0%);
  background-repeat: var(--hui-wpcfwr-background-repeat, repeat);
  background-size: var(--hui-wpcfwr-background-size, auto);
  border-bottom-color: var(--hui-wpcfwr-border-bottom-color, rgb(0, 0, 0));
  border-bottom-style: var(--hui-wpcfwr-border-bottom-style, none);
  border-bottom-width: var(--hui-wpcfwr-border-bottom-width, 0px);
  border-bottom-left-radius: var(--hui-wpcfwr-border-bottom-left-radius, 0px);
  border-bottom-right-radius: var(--hui-wpcfwr-border-bottom-right-radius, 0px);
  border-image-outset: var(--hui-wpcfwr-border-image-outset, 0);
  border-image-repeat: var(--hui-wpcfwr-border-image-repeat, stretch);
  border-image-slice: var(--hui-wpcfwr-border-image-slice, 100%);
  border-image-source: var(--hui-wpcfwr-border-image-source, none);
  border-image-width: var(--hui-wpcfwr-border-image-width, 1);
  border-left-color: var(--hui-wpcfwr-border-left-color, rgb(0, 0, 0));
  border-left-style: var(--hui-wpcfwr-border-left-style, none);
  border-left-width: var(--hui-wpcfwr-border-left-width, 0px);
  border-right-color: var(--hui-wpcfwr-border-right-color, rgb(0, 0, 0));
  border-right-style: var(--hui-wpcfwr-border-right-style, none);
  border-right-width: var(--hui-wpcfwr-border-right-width, 0px);
  border-top-color: var(--hui-wpcfwr-border-top-color, rgb(0, 0, 0));
  border-top-style: var(--hui-wpcfwr-border-top-style, none);
  border-top-width: var(--hui-wpcfwr-border-top-width, 0px);
  border-top-left-radius: var(--hui-wpcfwr-border-top-left-radius, 0px);
  border-top-right-radius: var(--hui-wpcfwr-border-top-right-radius, 0px);
  box-shadow: var(--hui-wpcfwr-box-shadow, none);
  cursor: var(--hui-wpcfwr-cursor, auto);
  outline-color: var(--hui-wpcfwr-outline-color, rgb(0, 0, 0));
  outline-offset: var(--hui-wpcfwr-outline-offset, 0px);
  outline-style: var(--hui-wpcfwr-outline-style, none);
  outline-width: var(--hui-wpcfwr-outline-width, 0px);
  scrollbar-color: var(--hui-wpcfwr-scrollbar-color, #bbb #eee);
  scrollbar-gutter: var(--hui-wpcfwr-scrollbar-gutter, auto);
  scrollbar-width: var(--hui-wpcfwr-scrollbar-width, thin);
}