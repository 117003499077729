[data-hui=Main] > main {
  /* LAYOUT-POSITION */
  position: var(--hui-ecmfij-position, static);
  top: var(--hui-ecmfij-top, auto);
  right: var(--hui-ecmfij-right, auto);
  bottom: var(--hui-ecmfij-bottom, auto);
  left: var(--hui-ecmfij-left, auto);
  z-index: var(--hui-ecmfij-z-index, auto);
  float: var(--hui-ecmfij-float, none);
  clear: var(--hui-ecmfij-clear, none);
  align-self: var(--hui-ecmfij-align-self, auto);
  order: var(--hui-ecmfij-order, 0);
  /* LAYOUT-SIZING */
  box-sizing: var(--hui-ecmfij-box-sizing, border-box);
  height: var(--hui-ecmfij-height, auto);
  max-height: var(--hui-ecmfij-max-height, none);
  min-height: var(--hui-ecmfij-min-height, auto);
  width: var(--hui-ecmfij-width, auto);
  max-width: var(--hui-ecmfij-max-width, none);
  min-width: var(--hui-ecmfij-min-width, auto);
  /* LAYOUT-MARGIN */
  margin-top: var(--hui-ecmfij-margin-top, 0px);
  margin-right: var(--hui-ecmfij-margin-right, 0px);
  margin-bottom: var(--hui-ecmfij-margin-bottom, 0px);
  margin-left: var(--hui-ecmfij-margin-left, 0px);
  /* LAYOUT-PADDING */
  padding-top: var(--hui-ecmfij-padding-top, 0px);
  padding-right: var(--hui-ecmfij-padding-right, 0px);
  padding-bottom: var(--hui-ecmfij-padding-bottom, 0px);
  padding-left: var(--hui-ecmfij-padding-left, 0px);
  /* LAYOUT-OVERFLOW */
  overflow-x: var(--hui-ecmfij-overflow-x, visible);
  overflow-y: var(--hui-ecmfij-overflow-y, visible);
  /* LAYOUT-FLEX */
  flex-basis: var(--hui-ecmfij-flex-basis, auto);
  flex-direction: var(--hui-ecmfij-flex-direction, row);
  flex-grow: var(--hui-ecmfij-flex-grow, 0);
  flex-shrink: var(--hui-ecmfij-flex-shrink, 1);
  flex-wrap: var(--hui-ecmfij-flex-wrap, nowrap);
  gap: var(--hui-ecmfij-gap, 0px);
  align-content: var(--hui-ecmfij-align-content, stretch);
  justify-content: var(--hui-ecmfij-justify-content, flex-start);
  align-items: var(--hui-ecmfij-align-items, stretch);
  /* TEXT */
  direction: var(--hui-ecmfij-direction, ltr);
  font-family: var(--hui-ecmfij-font-family, system-ui);
  font-size: var(--hui-ecmfij-font-size, 16px);
  font-stretch: var(--hui-ecmfij-font-stretch, 100%);
  font-style: var(--hui-ecmfij-font-style, normal);
  font-weight: var(--hui-ecmfij-font-weight, 400);
  letter-spacing: var(--hui-ecmfij-letter-spacing, normal);
  line-height: var(--hui-ecmfij-line-height, normal);
  text-align: var(--hui-ecmfij-text-align, start);
  text-align-last: var(--hui-ecmfij-text-align-last, auto);
  text-decoration-color: var(--hui-ecmfij-text-decoration-color, #000);
  text-decoration-line: var(--hui-ecmfij-text-decoration-line, none);
  text-decoration-style: var(--hui-ecmfij-text-decoration-style, solid);
  text-decoration-thickness: var(--hui-ecmfij-text-decoration-thickness, auto);
  text-indent: var(--hui-ecmfij-text-indent, 0px);
  text-overflow: var(--hui-ecmfij-text-overflow, clip);
  text-shadow: var(--hui-ecmfij-text-shadow, none);
  text-size-adjust: var(--hui-ecmfij-text-size-adjust, auto);
  text-transform: var(--hui-ecmfij-text-transform, none);
  text-wrap: var(--hui-ecmfij-text-wrap, wrap);
  vertical-align: var(--hui-ecmfij-vertical-align, baseline);
  white-space-collapse: var(--hui-ecmfij-white-space-collapse, collapse);
  word-break: var(--hui-ecmfij-word-break, normal);
  word-spacing: var(--hui-ecmfij-word-spacing, 0px);
  /* APPERANCE */
  visibility: var(--hui-ecmfij-visibility, visible);
  color: var(--hui-ecmfij-color, rgb(0, 0, 0));
  background-attachment: var(--hui-ecmfij-background-attachment, scroll);
  background-clip: var(--hui-ecmfij-background-clip, border-box);
  background-color: var(--hui-ecmfij-background-color, rgba(0, 0, 0, 0));
  background-image: var(--hui-ecmfij-background-image, none);
  background-origin: var(--hui-ecmfij-background-origin, padding-box);
  background-position-x: var(--hui-ecmfij-background-position-x, 0%);
  background-position-y: var(--hui-ecmfij-background-position-y, 0%);
  background-repeat: var(--hui-ecmfij-background-repeat, repeat);
  background-size: var(--hui-ecmfij-background-size, auto);
  border-bottom-color: var(--hui-ecmfij-border-bottom-color, rgb(0, 0, 0));
  border-bottom-style: var(--hui-ecmfij-border-bottom-style, none);
  border-bottom-width: var(--hui-ecmfij-border-bottom-width, 0px);
  border-bottom-left-radius: var(--hui-ecmfij-border-bottom-left-radius, 0px);
  border-bottom-right-radius: var(--hui-ecmfij-border-bottom-right-radius, 0px);
  border-image-outset: var(--hui-ecmfij-border-image-outset, 0);
  border-image-repeat: var(--hui-ecmfij-border-image-repeat, stretch);
  border-image-slice: var(--hui-ecmfij-border-image-slice, 100%);
  border-image-source: var(--hui-ecmfij-border-image-source, none);
  border-image-width: var(--hui-ecmfij-border-image-width, 1);
  border-left-color: var(--hui-ecmfij-border-left-color, rgb(0, 0, 0));
  border-left-style: var(--hui-ecmfij-border-left-style, none);
  border-left-width: var(--hui-ecmfij-border-left-width, 0px);
  border-right-color: var(--hui-ecmfij-border-right-color, rgb(0, 0, 0));
  border-right-style: var(--hui-ecmfij-border-right-style, none);
  border-right-width: var(--hui-ecmfij-border-right-width, 0px);
  border-top-color: var(--hui-ecmfij-border-top-color, rgb(0, 0, 0));
  border-top-style: var(--hui-ecmfij-border-top-style, none);
  border-top-width: var(--hui-ecmfij-border-top-width, 0px);
  border-top-left-radius: var(--hui-ecmfij-border-top-left-radius, 0px);
  border-top-right-radius: var(--hui-ecmfij-border-top-right-radius, 0px);
  box-shadow: var(--hui-ecmfij-box-shadow, none);
  cursor: var(--hui-ecmfij-cursor, auto);
  outline-color: var(--hui-ecmfij-outline-color, rgb(0, 0, 0));
  outline-offset: var(--hui-ecmfij-outline-offset, 0px);
  outline-style: var(--hui-ecmfij-outline-style, none);
  outline-width: var(--hui-ecmfij-outline-width, 0px);
  scrollbar-color: var(--hui-ecmfij-scrollbar-color, #bbb #eee);
  scrollbar-gutter: var(--hui-ecmfij-scrollbar-gutter, auto);
  scrollbar-width: var(--hui-ecmfij-scrollbar-width, thin);
}