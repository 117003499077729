[data-hui=NumberInput] {
  /* LAYOUT-POSITION */
  position: var(--hui-affhez-position, static);
  top: var(--hui-affhez-top, auto);
  right: var(--hui-affhez-right, auto);
  bottom: var(--hui-affhez-bottom, auto);
  left: var(--hui-affhez-left, auto);
  z-index: var(--hui-affhez-z-index, auto);
  float: var(--hui-affhez-float, none);
  clear: var(--hui-affhez-clear, none);
  align-self: var(--hui-affhez-align-self, auto);
  order: var(--hui-affhez-order, 0);
  /* LAYOUT-SIZING */
  box-sizing: var(--hui-affhez-box-sizing, border-box);
  height: var(--hui-affhez-height, auto);
  max-height: var(--hui-affhez-max-height, none);
  min-height: var(--hui-affhez-min-height, auto);
  width: var(--hui-affhez-width, auto);
  max-width: var(--hui-affhez-max-width, none);
  min-width: var(--hui-affhez-min-width, auto);
  /* LAYOUT-MARGIN */
  margin-top: var(--hui-affhez-margin-top, 0px);
  margin-right: var(--hui-affhez-margin-right, 0px);
  margin-bottom: var(--hui-affhez-margin-bottom, 0px);
  margin-left: var(--hui-affhez-margin-left, 0px);
  /* LAYOUT-PADDING */
  padding-top: var(--hui-affhez-padding-top, 0px);
  padding-right: var(--hui-affhez-padding-right, 0px);
  padding-bottom: var(--hui-affhez-padding-bottom, 0px);
  padding-left: var(--hui-affhez-padding-left, 0px);
  /* LAYOUT-OVERFLOW */
  overflow-x: var(--hui-affhez-overflow-x, visible);
  overflow-y: var(--hui-affhez-overflow-y, visible);
  /* LAYOUT-FLEX */
  flex-basis: var(--hui-affhez-flex-basis, auto);
  flex-direction: var(--hui-affhez-flex-direction, row);
  flex-grow: var(--hui-affhez-flex-grow, 0);
  flex-shrink: var(--hui-affhez-flex-shrink, 1);
  flex-wrap: var(--hui-affhez-flex-wrap, nowrap);
  gap: var(--hui-affhez-gap, 0px);
  align-content: var(--hui-affhez-align-content, stretch);
  justify-content: var(--hui-affhez-justify-content, flex-start);
  align-items: var(--hui-affhez-align-items, stretch);
  /* TEXT */
  direction: var(--hui-affhez-direction, ltr);
  font-family: var(--hui-affhez-font-family, system-ui);
  font-size: var(--hui-affhez-font-size, 16px);
  font-stretch: var(--hui-affhez-font-stretch, 100%);
  font-style: var(--hui-affhez-font-style, normal);
  font-weight: var(--hui-affhez-font-weight, 400);
  letter-spacing: var(--hui-affhez-letter-spacing, normal);
  line-height: var(--hui-affhez-line-height, normal);
  text-align: var(--hui-affhez-text-align, start);
  text-align-last: var(--hui-affhez-text-align-last, auto);
  text-decoration-color: var(--hui-affhez-text-decoration-color, #000);
  text-decoration-line: var(--hui-affhez-text-decoration-line, none);
  text-decoration-style: var(--hui-affhez-text-decoration-style, solid);
  text-decoration-thickness: var(--hui-affhez-text-decoration-thickness, auto);
  text-indent: var(--hui-affhez-text-indent, 0px);
  text-overflow: var(--hui-affhez-text-overflow, clip);
  text-shadow: var(--hui-affhez-text-shadow, none);
  text-size-adjust: var(--hui-affhez-text-size-adjust, auto);
  text-transform: var(--hui-affhez-text-transform, none);
  text-wrap: var(--hui-affhez-text-wrap, wrap);
  vertical-align: var(--hui-affhez-vertical-align, baseline);
  white-space-collapse: var(--hui-affhez-white-space-collapse, collapse);
  word-break: var(--hui-affhez-word-break, normal);
  word-spacing: var(--hui-affhez-word-spacing, 0px);
  /* APPERANCE */
  visibility: var(--hui-affhez-visibility, visible);
  color: var(--hui-affhez-color, rgb(0, 0, 0));
  background-attachment: var(--hui-affhez-background-attachment, scroll);
  background-clip: var(--hui-affhez-background-clip, border-box);
  background-color: var(--hui-affhez-background-color, rgba(0, 0, 0, 0));
  background-image: var(--hui-affhez-background-image, none);
  background-origin: var(--hui-affhez-background-origin, padding-box);
  background-position-x: var(--hui-affhez-background-position-x, 0%);
  background-position-y: var(--hui-affhez-background-position-y, 0%);
  background-repeat: var(--hui-affhez-background-repeat, repeat);
  background-size: var(--hui-affhez-background-size, auto);
  border-bottom-color: var(--hui-affhez-border-bottom-color, rgb(0, 0, 0));
  border-bottom-style: var(--hui-affhez-border-bottom-style, none);
  border-bottom-width: var(--hui-affhez-border-bottom-width, 0px);
  border-bottom-left-radius: var(--hui-affhez-border-bottom-left-radius, 0px);
  border-bottom-right-radius: var(--hui-affhez-border-bottom-right-radius, 0px);
  border-image-outset: var(--hui-affhez-border-image-outset, 0);
  border-image-repeat: var(--hui-affhez-border-image-repeat, stretch);
  border-image-slice: var(--hui-affhez-border-image-slice, 100%);
  border-image-source: var(--hui-affhez-border-image-source, none);
  border-image-width: var(--hui-affhez-border-image-width, 1);
  border-left-color: var(--hui-affhez-border-left-color, rgb(0, 0, 0));
  border-left-style: var(--hui-affhez-border-left-style, none);
  border-left-width: var(--hui-affhez-border-left-width, 0px);
  border-right-color: var(--hui-affhez-border-right-color, rgb(0, 0, 0));
  border-right-style: var(--hui-affhez-border-right-style, none);
  border-right-width: var(--hui-affhez-border-right-width, 0px);
  border-top-color: var(--hui-affhez-border-top-color, rgb(0, 0, 0));
  border-top-style: var(--hui-affhez-border-top-style, none);
  border-top-width: var(--hui-affhez-border-top-width, 0px);
  border-top-left-radius: var(--hui-affhez-border-top-left-radius, 0px);
  border-top-right-radius: var(--hui-affhez-border-top-right-radius, 0px);
  box-shadow: var(--hui-affhez-box-shadow, none);
  cursor: var(--hui-affhez-cursor, auto);
  outline-color: var(--hui-affhez-outline-color, rgb(0, 0, 0));
  outline-offset: var(--hui-affhez-outline-offset, 0px);
  outline-style: var(--hui-affhez-outline-style, none);
  outline-width: var(--hui-affhez-outline-width, 0px);
  scrollbar-color: var(--hui-affhez-scrollbar-color, #bbb #eee);
  scrollbar-gutter: var(--hui-affhez-scrollbar-gutter, auto);
  scrollbar-width: var(--hui-affhez-scrollbar-width, thin);
  /* DISPLAY */
  display: var(--hui-affhez-display, block);
  flex: var(--hui-affhez-flex, initial);
}
[data-hui=NumberInput] > input {
  /* LAYOUT-POSITION */
  position: var(--hui-hqtsye-position, static);
  top: var(--hui-hqtsye-top, auto);
  right: var(--hui-hqtsye-right, auto);
  bottom: var(--hui-hqtsye-bottom, auto);
  left: var(--hui-hqtsye-left, auto);
  z-index: var(--hui-hqtsye-z-index, auto);
  float: var(--hui-hqtsye-float, none);
  clear: var(--hui-hqtsye-clear, none);
  align-self: var(--hui-hqtsye-align-self, auto);
  order: var(--hui-hqtsye-order, 0);
  /* LAYOUT-SIZING */
  box-sizing: var(--hui-hqtsye-box-sizing, border-box);
  height: var(--hui-hqtsye-height, auto);
  max-height: var(--hui-hqtsye-max-height, none);
  min-height: var(--hui-hqtsye-min-height, auto);
  width: var(--hui-hqtsye-width, auto);
  max-width: var(--hui-hqtsye-max-width, none);
  min-width: var(--hui-hqtsye-min-width, auto);
  /* LAYOUT-MARGIN */
  margin-top: var(--hui-hqtsye-margin-top, 0px);
  margin-right: var(--hui-hqtsye-margin-right, 0px);
  margin-bottom: var(--hui-hqtsye-margin-bottom, 0px);
  margin-left: var(--hui-hqtsye-margin-left, 0px);
  /* LAYOUT-PADDING */
  padding-top: var(--hui-hqtsye-padding-top, 0px);
  padding-right: var(--hui-hqtsye-padding-right, 0px);
  padding-bottom: var(--hui-hqtsye-padding-bottom, 0px);
  padding-left: var(--hui-hqtsye-padding-left, 0px);
  /* LAYOUT-OVERFLOW */
  overflow-x: var(--hui-hqtsye-overflow-x, visible);
  overflow-y: var(--hui-hqtsye-overflow-y, visible);
  /* LAYOUT-FLEX */
  flex-basis: var(--hui-hqtsye-flex-basis, auto);
  flex-direction: var(--hui-hqtsye-flex-direction, row);
  flex-grow: var(--hui-hqtsye-flex-grow, 0);
  flex-shrink: var(--hui-hqtsye-flex-shrink, 1);
  flex-wrap: var(--hui-hqtsye-flex-wrap, nowrap);
  gap: var(--hui-hqtsye-gap, 0px);
  align-content: var(--hui-hqtsye-align-content, stretch);
  justify-content: var(--hui-hqtsye-justify-content, flex-start);
  align-items: var(--hui-hqtsye-align-items, stretch);
  /* TEXT */
  direction: var(--hui-hqtsye-direction, ltr);
  font-family: var(--hui-hqtsye-font-family, system-ui);
  font-size: var(--hui-hqtsye-font-size, 16px);
  font-stretch: var(--hui-hqtsye-font-stretch, 100%);
  font-style: var(--hui-hqtsye-font-style, normal);
  font-weight: var(--hui-hqtsye-font-weight, 400);
  letter-spacing: var(--hui-hqtsye-letter-spacing, normal);
  line-height: var(--hui-hqtsye-line-height, normal);
  text-align: var(--hui-hqtsye-text-align, start);
  text-align-last: var(--hui-hqtsye-text-align-last, auto);
  text-decoration-color: var(--hui-hqtsye-text-decoration-color, #000);
  text-decoration-line: var(--hui-hqtsye-text-decoration-line, none);
  text-decoration-style: var(--hui-hqtsye-text-decoration-style, solid);
  text-decoration-thickness: var(--hui-hqtsye-text-decoration-thickness, auto);
  text-indent: var(--hui-hqtsye-text-indent, 0px);
  text-overflow: var(--hui-hqtsye-text-overflow, clip);
  text-shadow: var(--hui-hqtsye-text-shadow, none);
  text-size-adjust: var(--hui-hqtsye-text-size-adjust, auto);
  text-transform: var(--hui-hqtsye-text-transform, none);
  text-wrap: var(--hui-hqtsye-text-wrap, wrap);
  vertical-align: var(--hui-hqtsye-vertical-align, baseline);
  white-space-collapse: var(--hui-hqtsye-white-space-collapse, collapse);
  word-break: var(--hui-hqtsye-word-break, normal);
  word-spacing: var(--hui-hqtsye-word-spacing, 0px);
  /* APPERANCE */
  visibility: var(--hui-hqtsye-visibility, visible);
  color: var(--hui-hqtsye-color, rgb(0, 0, 0));
  background-attachment: var(--hui-hqtsye-background-attachment, scroll);
  background-clip: var(--hui-hqtsye-background-clip, border-box);
  background-color: var(--hui-hqtsye-background-color, rgba(0, 0, 0, 0));
  background-image: var(--hui-hqtsye-background-image, none);
  background-origin: var(--hui-hqtsye-background-origin, padding-box);
  background-position-x: var(--hui-hqtsye-background-position-x, 0%);
  background-position-y: var(--hui-hqtsye-background-position-y, 0%);
  background-repeat: var(--hui-hqtsye-background-repeat, repeat);
  background-size: var(--hui-hqtsye-background-size, auto);
  border-bottom-color: var(--hui-hqtsye-border-bottom-color, rgb(0, 0, 0));
  border-bottom-style: var(--hui-hqtsye-border-bottom-style, none);
  border-bottom-width: var(--hui-hqtsye-border-bottom-width, 0px);
  border-bottom-left-radius: var(--hui-hqtsye-border-bottom-left-radius, 0px);
  border-bottom-right-radius: var(--hui-hqtsye-border-bottom-right-radius, 0px);
  border-image-outset: var(--hui-hqtsye-border-image-outset, 0);
  border-image-repeat: var(--hui-hqtsye-border-image-repeat, stretch);
  border-image-slice: var(--hui-hqtsye-border-image-slice, 100%);
  border-image-source: var(--hui-hqtsye-border-image-source, none);
  border-image-width: var(--hui-hqtsye-border-image-width, 1);
  border-left-color: var(--hui-hqtsye-border-left-color, rgb(0, 0, 0));
  border-left-style: var(--hui-hqtsye-border-left-style, none);
  border-left-width: var(--hui-hqtsye-border-left-width, 0px);
  border-right-color: var(--hui-hqtsye-border-right-color, rgb(0, 0, 0));
  border-right-style: var(--hui-hqtsye-border-right-style, none);
  border-right-width: var(--hui-hqtsye-border-right-width, 0px);
  border-top-color: var(--hui-hqtsye-border-top-color, rgb(0, 0, 0));
  border-top-style: var(--hui-hqtsye-border-top-style, none);
  border-top-width: var(--hui-hqtsye-border-top-width, 0px);
  border-top-left-radius: var(--hui-hqtsye-border-top-left-radius, 0px);
  border-top-right-radius: var(--hui-hqtsye-border-top-right-radius, 0px);
  box-shadow: var(--hui-hqtsye-box-shadow, none);
  cursor: var(--hui-hqtsye-cursor, auto);
  outline-color: var(--hui-hqtsye-outline-color, rgb(0, 0, 0));
  outline-offset: var(--hui-hqtsye-outline-offset, 0px);
  outline-style: var(--hui-hqtsye-outline-style, none);
  outline-width: var(--hui-hqtsye-outline-width, 0px);
  scrollbar-color: var(--hui-hqtsye-scrollbar-color, #bbb #eee);
  scrollbar-gutter: var(--hui-hqtsye-scrollbar-gutter, auto);
  scrollbar-width: var(--hui-hqtsye-scrollbar-width, thin);
}
[data-hui=NumberInput] > input::placeholder {
  font-size: var(--hui-ltpgzd-font-size, initial);
}